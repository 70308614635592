export const SpecializedServices = {
  services: [
    {
      group: "none",
      Organization: "Prospera",
      "link": "https://prosperausa.org/",
    },
    {
      group: "none",
      Organization: "CAMACOL - Latin Chamber of Commerce of U.S.A.",
      "link": "https://camacol.org/",
    },
    {
      group: "none",
      Organization: "Consular Corps of Miami",
      "link":
        "https://www.miamidade.gov/business/international-consulates.asp",
    },
    {
      group: "Trade Offices ie. ProColombia, ProChile",
      Organization: "Argentine American Chamber of Commerce of Florida",
      "link": "https://argentineamerican.org/",
    },
    {
      group: "Trade Offices ie. ProColombia, ProChile",
      Organization: "Brazil American Chamber of Commerce of Florida",
      "link": "https://www.brazilchamber.org/",
    },
    {
      group: "Trade Offices ie. ProColombia, ProChile",
      Organization: "Canada-Florida Chamber of Commerce",
      "link": "https://canadafloridachamber.com/",
    },
    {
      group: "Trade Offices ie. ProColombia, ProChile",
      Organization: "British American Business Council Miami",
      "link": "https://babcmiami.org/",
    },
    {
      group: "Trade Offices ie. ProColombia, ProChile",
      Organization: "Chile-US Chamber of Commerce",
      "link": "http://www.chileus.org/",
    },
    {
      group: "Trade Offices ie. ProColombia, ProChile",
      Organization: "Colombian American Chamber of Commerce",
      "link": "http://colombiamericancha.wixsite.com/mysite",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "European American Chamber of Commerce Florida",
      "link": "https://eaccfl.com/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "French American Chamber of Commerce",
      "link": "https://www.faccmiami.com/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "German American Business Chamber of South Florida Inc.",
      "link": "https://www.gabc.us/Affiliations",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Honduras-USA Chamber of Commerce",
      "link": "http://haccw.org/contact",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Guyanese American Chamber of Commerce",
      "link":
        "https://www.facebook.com/pages/category/Nonprofit-Organization/Guyanese-American-Chamber-of-CommerceInc-485655798303411/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Haitian American Chamber of Commerce of Florida",
      "link": "http://www.haccof.com/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Indo-U.S. Chamber of Commerce Inc., South Florida",
      "link": "https://www.iuccsfl.com/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Peruvian American Chamber of Commerce",
      "link": "http://www.peruvianchamber.org",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Italy-America Chamber of Commerce Southeast",
      "link": "https://www.iaccse.com/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Jamaica USA Chamber of Commerce",
      "link": "http://www.jamaicausachamber.org/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Panamanian American Chamber of Commerce",
      "link": "http://www.paccmiami.com/web/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Romanian American Chamber of Commerce -Florida Chapter",
      "link": "http://www.racc.ro/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "South Flroida Hispanic Chamber of Commerce",
      "link": "https://sflhcc.com/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "South Florida Irish American Chamber of Commerce",
      "link": "https://sfiacc.org/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Spain-U.S. Chamber of Commerce",
      "link": "https://spainuschamber.com/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Swedish-American Chamer of Commerce -Florida",
      "link": "http://www.sacc-florida.com/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "Taiwan Trade Center Miami",
      "link": "https://miami.taiwantrade.com/home",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization: "U.S. - Mexico Chamber of Commerce, Inter-American Chapter",
      "link": "http://www.usmcocfl.org/",
    },
    {
      group:
        "Dominician International Business, Tourism & Development Federation, Inc.",
      Organization:
        "Venezuelan-American Chamber of Commerce of the United Staes, Inc.",
      "link": "https://www.venezuelanchamber.org/",
    },
  ],
}

export default SpecializedServices
