import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import "./immigration-attorneys.css"
import Services from "../data/specializedServices"
import "./special-services.css"
import { Link } from "gatsby"

const SpecializedServices = () => {
  const [services, setServices] = useState(Services.services)
  return (
    <Layout>
      <SEO title="Specialized Services" />
      <div className="container primary-bg">
        <div>
          <div>
            <Link
              className="linkHeader"
              to="/international-business-and-non-resident"
            >
              International Business and Non-Resident Resources
            </Link>
          </div>
          <div>
            <label className="additonalFundingTitle">
              Specialized Services
            </label>
          </div>
        </div>
        {services.map((service, index) => {
          return (
            <div>
              <hr />
              <label className="servicesHeader">{service.Organization}</label>
              <div>
                <FontAwesomeIcon
                  className="iconStyle"
                  size="lg"
                  icon={faGlobe}
                />
                <span className="spanStyle">
                  <a
                    className="servicesText"
                    href={service.link}
                    target="_blank"
                  >
                    {service.link}
                  </a>
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default SpecializedServices
